import React, { useEffect } from 'react';

import { useApplication } from '@common/application';
import { useChat, Namespace, ChatArgs, Visibility } from '@components/chat/useChat';
import { OpenAPI } from '@dc/client/OpenAPI';
import { useSession } from '@dxp-auth';

type ChatProps = {
  namespace: Namespace;
  fields?: {
    chatNamespace?: {
      id?: string;
      url?: string;
      name?: string;
      displayName?: string;
      fields?: {
        namespace?: {
          value?: Namespace;
        };
      };
    };
    chatProviderTopic?: {
      id?: string;
      url?: string;
      name?: string;
      displayName?: string;
      fields?: {
        namespace?: {
          value?: string;
        };
      };
    };
  };
};

const Chat: React.FC<ChatProps> = ({ namespace, fields }) => {
  const [mount] = useChat();
  const { searchParams } = useApplication();
  const topicToChat = searchParams.get('topicToChat');
  const textToChat = searchParams.get('textToChat');
  const stateToChat = searchParams.get('stateToChat') as Visibility;

  const { data: session, status } = useSession() ?? {};

  const namespaceJSS = fields?.chatNamespace?.fields?.namespace?.value;
  const topicJSS = fields?.chatProviderTopic?.fields?.namespace?.value;

  useEffect(() => {
    return () => {
      mount({ visibility: 'hidden' });
    };
  }, []);

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    const token = session?.idToken ?? OpenAPI.TOKEN;

    const chatArgs: ChatArgs = {
      askText: textToChat ?? undefined,
      namespace: namespace ?? namespaceJSS,
      topic: topicToChat ?? topicJSS,
      context:
        token || textToChat
          ? {
              variables: {
                event: textToChat ? 'start_with_question' : undefined,
                token: token,
              },
            }
          : undefined,
      visibility: stateToChat ?? (textToChat || topicToChat ? 'open' : undefined),
    };

    mount({
      ...chatArgs,
      api: {
        getConversationAuthToken: () => token,
      },
    });
  }, [mount, namespace, namespaceJSS, session, stateToChat, status, textToChat, topicJSS, topicToChat]);

  return null;
};

export default Chat;
